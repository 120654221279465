@import url("https://fonts.googleapis.com/css?family=Muli&display=swap");
@import url("https://fonts.googleapis.com/css?family=Quicksand&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Raleway+Dots&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import url('https://fonts.googleapis.com/css?family=Inter');

.trace {
  font-family: 'Raleway Dots';
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url("./fonts/Lato-Regular.ttf") format("ttf"),
    url("./fonts/Lato-Bold.ttf") format("ttf");
  font-weight: bold;
  font-display: swap;
}

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  user-select: none;
}

.login-main {
  background-color: #fff;
}

.login-main .row {
  height: 100vh;
  padding: 20px;
  align-items: center;
}

/* .login-main .left {
  height: 100%;
  padding: 0;
} */

.login-main .left .inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* padding: 50px; */
}

.login-main .right {
  margin-left: 0px;
}
@media(min-width:992px){
  .login-main .right {
    margin-left: 70px;
    background-color: #ffffff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
  }
}


.login-main .right .text {
  border-radius: 7px;
  height: 50px;
  /* width: 502px; */
  margin-top: 38px;
  border: 1px solid #8c8c8d;
  padding: 10px;
}

.login-main .right #text1 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.login-main .right #text2 {
  margin-bottom: 10px;
  margin-top: 10px;
}

.text-white {
  border-radius: 10px;
  margin-bottom: 5px;
}

.login-main .right .text:focus {
  outline: 1px solid #5A36F6;
}

.login-main .right .text::placeholder {
  font-size: 16px;
  opacity: 0.5;
}

.login-main .right .label {
  color: #232323;
  font-size: 14px;
  font-weight: bold;

}

.login-main .right #forget {
  display: flex;
  justify-content: right;
  color: #5A36F6;
  font-size: 14px;
  text-align: center;
  align-items: center;
  width: 502px;
  margin-bottom: 38px;
}

.login-main .right .button {
  margin-top: 20px;
  margin-bottom: 20px;
  background-color: #5A36F6;
  color: #fff;
  border-radius: 8px;
  height: 50px;
}

.login-main .right .button:focus {
  background-color: #5A36F6;
  color: #fff;
}

.login-main .right .create {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #6542FF;
  text-align: center;
  align-items: center;
}

.register-main {
  background-color: #fff;
}

.register-main .row {
  height: 100vh;
  padding: 10px;
  align-items: center;
  margin: auto;
}

.register-main .left {
  height: 100%;
  padding: 0;
}

.register-main .left .inner {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  /* padding: 50px; */
}

.register-main .right {
  /* margin-left: 60px; */
  background-color: #ffffff;
  padding: 25px;
  border-radius: 10px;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.18);
}

.register-main .right .error {
  margin-top: -12px;
  font-size: 14px;
}

.register-main .right .text {
  border-radius: 7px;
  height: 50px;
  margin-top: 38px;
  border: 1px solid #8c8c8d;
}

.register-main .right .text:focus {
  outline: 0px solid #5A36F6;
}

.register-main .right .text::placeholder {
  font-size: 16px;
  opacity: 0.5;
}

.register-main .right .label {
  color: #232323;
  font-size: 14px;
  font-weight: bold;
}

.register-main .right #text1 {
  margin-top: 10px;
  margin-bottom: 15px;
}

.register-main .right #text2,
#text3 {
  margin-top: 6px;
  margin-bottom: 15px;
}

.register-main .right .button {
  margin-top: 38px;
  margin-bottom: 20px;
  background-color: #5A36F6;
  color: #fff;
  border-radius: 8px;
  height: 50px;
}

.register-main .right .button:focus {
  background-color: #5A36F6;
  color: #fff;
}

.register-main .right .create {
  display: flex;
  justify-content: center;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #6542FF;
  text-align: center;
  align-items: center;
}

.dashboard .logout {
  height: 60px;
  background-image: linear-gradient(to right, #15A8E1, #5DC8A7, #00DCCF);
  display: flex;
  justify-content: space-between;
}

.dashboard .logout .logout-button {
  color: #6853C2;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  width: 87px;
  height: 32px;
  margin: 14px;
}

/* .dashboard .logout .menu1 {
  width: 87px;
  height: 32px;
  margin: 14px;
  position: absolute;
  right: 20px;
} */

.dashboard .logo {
  height: 43px;
  width: 125px;
  margin-top: 7px;
  margin-left: 5px;
}

.dashboard .nav-bar {
  height: 1000px;
  background-color: #6853C2;
  display: flex;
  flex-direction: column;
}

.dashboard .nav-bar .img:focus,
.img:hover {
  background: #4328B7;
  border-left: 7px solid #FFFFFF;
  border-radius: 5px;
}

.dashboard .nav-bar .img {
  height: 10%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dashboard .left-side {
  background: #DFDAF8;
  height: 1000px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.dashboard .left-side .heading {
  color: gray;
  background: #FFFFFF;
  border: 1px solid #FFFFFF;
  padding: 10px;
  margin-top: 10px;
}

.dashboard .center {
  background-color: ghostwhite;
  height: 116px;
}

.dashboard .center .heading {
  color: gray;
  background: #FFFFFF;
  border: 0.5px solid gray;
  padding: 10px;
  margin-top: 10px;
  width: 164px;
  border-radius: 4px;
}

.dashboard .center .worksheet {
  border: 2px solid #4328B7;
  height: 860px;
  margin-top: 14px;
}

.dashboard .right {
  background-color: ghostwhite;
  height: 1000px;
}




.clock {
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 4px solid black;
  /* box-shadow:  -8px 8px 15px rgba(225,225,225,0.05),
       20px 20px 20px rgba(0,0,0,0.2),
       inset -8px -8px 15px rgba(225,225,225,0.05),
       inset 20px 20px 20px rgba(0,0,0,0.2); */
  position: relative;
}

.clock::after {
  content: '';
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 11;
}

.clock .number {
  --roation: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: rotate(var(--roation));
  font-size: 1.5rem;
  padding: 18px;
}

.number::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 10px;
  background-color: black;
}

.number1::before,
.number2::before,
.number3::before,
.number4::before,
.number5::before,
.number6::before,
.number7::before,
.number8::before,
.number9::before,
.number10::before,
.number11::before,
.number12::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 20px;
  background-color: black;
}

.clock .number0_1 {
  --roation: 06deg
}

.clock .number0_2 {
  --roation: 12deg
}

.clock .number0_3 {
  --roation: 18deg
}

.clock .number0_4 {
  --roation: 24deg
}

.clock .number1 {
  --roation: 30deg
}

.clock .number1_1 {
  --roation: 36deg
}

.clock .number1_2 {
  --roation: 42deg
}

.clock .number1_3 {
  --roation: 48deg
}

.clock .number1_4 {
  --roation: 54deg
}

.clock .number2 {
  --roation: 60deg
}

.clock .number1_6 {
  --roation: 66deg
}

.clock .number1_7 {
  --roation: 72deg
}

.clock .number1_8 {
  --roation: 78deg
}

.clock .number1_9 {
  --roation: 84deg
}

.clock .number3 {
  --roation: 90deg
}

.clock .number2_1 {
  --roation: 96deg
}

.clock .number2_2 {
  --roation: 102deg
}

.clock .number2_3 {
  --roation: 108deg
}

.clock .number2_4 {
  --roation: 114deg
}

.clock .number4 {
  --roation: 120deg
}

.clock .number2_6 {
  --roation: 126deg
}

.clock .number2_7 {
  --roation: 132deg
}

.clock .number2_8 {
  --roation: 138deg
}

.clock .number2_9 {
  --roation: 144deg
}

.clock .number5 {
  --roation: 150deg
}

.clock .number3_1 {
  --roation: 156deg
}

.clock .number3_2 {
  --roation: 162deg
}

.clock .number3_3 {
  --roation: 168deg
}

.clock .number3_4 {
  --roation: 174deg
}

.clock .number6 {
  --roation: 180deg
}

.clock .number3_6 {
  --roation: 186deg
}

.clock .number3_7 {
  --roation: 192deg
}

.clock .number3_8 {
  --roation: 198deg
}

.clock .number3_9 {
  --roation: 204deg
}

.clock .number7 {
  --roation: 210deg
}

.clock .number4_1 {
  --roation: 216deg
}

.clock .number4_2 {
  --roation: 222deg
}

.clock .number4_3 {
  --roation: 228deg
}

.clock .number4_4 {
  --roation: 234deg
}

.clock .number8 {
  --roation: 240deg
}

.clock .number4_6 {
  --roation: 246deg
}

.clock .number4_7 {
  --roation: 252deg
}

.clock .number4_8 {
  --roation: 258deg
}

.clock .number4_9 {
  --roation: 264deg
}

.clock .number9 {
  --roation: 270deg
}

.clock .number5_1 {
  --roation: 276deg
}

.clock .number5_2 {
  --roation: 282deg
}

.clock .number5_3 {
  --roation: 288deg
}

.clock .number5_4 {
  --roation: 294deg
}

.clock .number10 {
  --roation: 300deg
}

.clock .number5_6 {
  --roation: 306deg
}

.clock .number5_7 {
  --roation: 312deg
}

.clock .number5_8 {
  --roation: 318deg
}

.clock .number5_9 {
  --roation: 324deg
}

.clock .number11 {
  --roation: 330deg
}

.clock .number6_1 {
  --roation: 336deg
}

.clock .number6_2 {
  --roation: 342deg
}

.clock .number6_3 {
  --roation: 348deg
}

.clock .number6_4 {
  --roation: 354deg
}


.number1 div {
  transform: rotate(-30deg);
}

.number2 div {
  transform: rotate(-60deg);
}

.number3 div {
  transform: rotate(-90deg);
}

.number4 div {
  transform: rotate(-120deg);
}

.number5 div {
  transform: rotate(-150deg);
}

.number6 div {
  transform: rotate(-180deg);

}

.number7 div {
  transform: rotate(-210deg);
}

.number8 div {
  transform: rotate(-240deg);
}

.number9 div {
  transform: rotate(-270deg);
}

.number10 div {
  transform: rotate(-300deg);
}

.number11 div {
  transform: rotate(-330deg);
}


.clock .hand {
  /* --roation:0; */
  position: absolute;
  bottom: 50%;
  left: 50%;

  border: 1px solid white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: black;
  transform-origin: bottom;
  /* transform: translate(-50%) rotate(calc(var(--roation)* 1deg)); */
  z-index: 10;
}

.clock .hand.second {
  width: 5px;
  height: 45%;
  background-color: red;
}

.clock .hand.minute {
  width: 7px;
  height: 40%;
  background-color: black;
}

.clock .hand.hour {
  width: 10px;
  height: 30%;
  background-color: black;
}

.clock1 {
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 5px solid black;
  position: relative;
}

.clock1::after {
  content: '';
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: black;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  z-index: 11;
}

.clock1 .num {
  --roation: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  transform: rotate(var(--roation));

  font-size: 1.5rem;
  padding: 18px;
}

.num::before {
  content: '';
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 5px;
  height: 10px;
  background-color: black;
}



.clock1 .num1_1 {
  --roation: 36deg
}

.clock1 .num1_2 {
  --roation: 72deg
}

.clock1 .num1_3 {
  --roation: 108deg
}

.clock1 .num1_4 {
  --roation: 144deg
}

.clock1 .num1_5 {
  --roation: 180deg
}

.clock1 .num1_6 {
  --roation: 216deg
}

.clock1 .num1_7 {
  --roation: 252deg
}

.clock1 .num1_8 {
  --roation: 288deg
}

.clock1 .num1_9 {
  --roation: 324deg
}

.clock1 .num2 {
  --roation: 360deg
}



.num1_1 div {
  transform: rotate(-36deg);
}

.num1_2 div {
  transform: rotate(-72deg);
}

.num1_3 div {
  transform: rotate(-108deg);
}

.num1_4 div {
  transform: rotate(-144deg);
}

.num1_5 div {
  transform: rotate(-180deg);
}

.num1_6 div {
  transform: rotate(-216deg);

}

.num1_7 div {
  transform: rotate(-252deg);
}

.num1_8 div {
  transform: rotate(-288deg);
}

.num1_9 div {
  transform: rotate(-324deg);
}

.num2 div {
  transform: rotate(-360deg);
}



.clock1 .hand {
  /* --roation:0; */
  position: absolute;
  bottom: 50%;
  left: 50%;

  border: 1px solid white;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
  background-color: black;
  transform-origin: bottom;
  /* transform: translate(-50%) rotate(calc(var(--roation)* 1deg)); */
  z-index: 10;
}


.clock1 .hand.hour {
  width: 10px;
  height: 23%;
  background-color: black;
}

#container1 {
  height: 300px;
  overflow: hidden;
  position: relative;
  width: 270px;
}

#container1 div {
  position: absolute;
}

#beaker {
  border: 5px solid black;
  border-top: 0;
  border-radius: 0 0 30px 30px;
  height: 220px;
  left: 39px;
  bottom: 0;
  width: 200px;
}

#beaker:before {
  border: 5px solid black;
  border-bottom: 0;
  border-radius: 0 45px 0 0;
  content: '';
  height: 50px;
  position: absolute;
  top: -45px;
  width: 89px;
  margin-left: -40px;
}

#beaker:before {
  left: -50px;
}

#beaker:after {
  border-left: 5px solid black;
  height: 49px;
  content: '';
  position: absolute;
  left: 50%;
  margin-left: 95px;
  margin-top: -45px;
}

#liquid {
  border: 5px solid black;
  border-radius: 0 0 30px 30px;
  bottom: 0;
  height: 25px;
  overflow: hidden;
  width: 200px;
  margin-left: -6px;
  margin-bottom: -6px;
}


#oval {
  width: 127%;
  border: 2px solid black;
  margin-top: -49px;
  margin-left: -47px;
  background-color: transparent;

}

.line {
  width: 10%;
  text-align: center;
  border: 2px solid black;
  margin-left: 172px;
}

.line1 {
  width: 7%;
  text-align: center;
  border: 2px solid black;
  margin-left: 180px;
}

#line11 {
  margin-top: 194px;
}

#line12 {
  margin-top: 180.5px;
}

#line13 {
  margin-top: 168px;
}

#line14 {
  margin-top: 154.5px;
}

#line15 {
  margin-top: 140px;
}

#line16 {
  margin-top: 126.5px;
}

#line17 {
  margin-top: 113px;
}

#line18 {
  margin-top: 100.5px;
}

#line19 {
  margin-top: 87px;
}

#line20 {
  margin-top: 73.5px;
}

#line21 {
  margin-top: 61px;
}

#line22 {
  margin-top: 46px;
}

#line23 {
  margin-top: 33px;
}

#line24 {
  margin-top: 18px;
}

#line25 {
  margin-top: 5px;
}

#line26 {
  margin-top: -10px;
}

#line27 {
  margin-top: -23px;
}

.amount {
  text-align: center;
  margin-left: -39px;
  margin-top: -80%;
}

#remove {
  background-color: "#FFF";
  box-shadow: "0px 0px 17px 0px rgb(204 204 204 / 64%)";
  height: 842px;
  width: 595px;

}

.css-1rwx9vz {
  background-color: #D1D1D1;
}

.square {
  height: 100px;
  width: 100px;
  border: 1px solid black;
}

#main {
  display: flex;
}

.ant-dropdown-trigger {
  margin: auto;
  padding: 10px;
}

.imagePreview {
  background-color: #fff;
  margin: 7px;
  position: relative;
  max-width: fit-content;
}

.dropdown-menu li {
  position: relative;
}

.dropdown-menu .dropdown-submenu {
  display: none;
  position: absolute;
  left: 100%;
  top: -7px;
}

.dropdown-menu .dropdown-submenu-left {
  right: 100%;
  left: auto;
}

.dropdown-menu>li:hover>.dropdown-submenu {
  display: block;
}

.menu-button {
  color: #6853C2;
  background: #FFFFFF;
  border-radius: 4px;
  border: 1px solid #FFFFFF;
  padding: 6px 20px;
  font-size: 16px;
}
.modal-dialog{
  width:300px;
  position: absolute;
  right: 15px;
  top: 30px;
}
.calendar {
  margin: 0 auto 20px;
  /* width: 1000px; */
}

.controlWrap {
  width: 630px;
}




.currentYear {
  font-size: 16px;
  line-height: 1;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #000000;
  width: 285px;
  margin-bottom: 8px;
}

/* controls  */

.controls {
  display: flex;
  justify-content: inherit;
  align-items: center;
  flex-direction: row;
  margin: 40px 0 0;
  position: relative;
  top: -28px;
}

.controls .btn {
  font-size: 9px;
  line-height: 0;
  font-weight: 400;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #000000;
  border: none;
  box-shadow: none;
  margin: 0 36px 0 0;
  cursor: pointer;
  border-radius: 8px;
}

/* year  */

.year {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  padding-bottom: 8px;
}

/* months */

.month {
  width: 7.5%;
  box-sizing: border-box;
  flex: 1 1 80px;
  margin: 0 0 8px;
}

.singleCol .month {
  width: 100%;
}

.twoCol .month {
  width: 50%;
}

.monthName {
  font-size: 8px;
  line-height: 1;
  font-weight: 500;
  font-style: normal;
  text-transform: none;
  text-align: center;
  color: #000000;
  padding: 0;
  width: 70px;
  margin: 0 0 5px;
}

.month .content {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100px;
}

/* days */

.day {
  width: 13.166667%;
  text-align: center;
  box-sizing: border-box;
  font-size: 7px;
}

.dayOfTheWeek .day {
  font-weight: 500;
}

.booked {
  background-color: #000000;
  color: #ffffff;
  display: none;
}

.isLateCheckout {
  position: relative;
  background-color: transparent;
  overflow: hidden;
  color: #ffffff;
}

.isLateCheckout>span {
  background: white;
  color: #000000;
  padding: 0px 3px;
}

.isLateCheckout>span::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(248, 80, 50, 1);
  background: linear-gradient(to bottom right, #000000 50%, transparent 50%);
  z-index: -1;
}

/* key */

.key {
  display: none;
  flex-direction: row;
  margin: 0 absolute
}

.key .type {
  margin: 0 10px 0 0;
}

.key .type span {
  position: relative;
  display: flex;
  line-height: 30px;
}

.key .type span::before {
  content: '';
  display: block;
  width: 30px;
  height: 30px;
  margin: 0 10px 0 0;
}

.bookedType span::before {
  background: #000000;
}

.lateCheckoutType span::before {
  background: rgba(248, 80, 50, 1);
  background: linear-gradient(to bottom right, #000000 50%, transparent 50%);
}

#abacus {
  height: 100px;
  width: 276px;
  padding-left: 40px;
  margin: auto;
  margin-left: 5px;
}

.rows {
  background-color: #000000;
  display: inline-flex;
  flex-direction: column-reverse;
  margin-right: 36px;
  height: 330px;
  width: 8px;
}

.column {
  margin: 2px;
  margin-left: -11px;
  padding: 15px;
  height: 23px;
  width: 20px;
  background-color: #808080;
  border-radius: 15px;
  position: relative;
}

.demo {
  margin-bottom: -46px;
  margin-left: -11px;
}

.abacus-holder {
  padding-left: 10px;
  border-bottom: 6px solid black;
  width: 276px;
}

.circle {
  width: 300px;
  height: 300px;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  border: 3px solid black;
  position: relative;
}

.circle .needle {
  position: absolute;
  bottom: 50%;
  left: 50%;
  background-color: black;
  transform-origin: bottom;
  z-index: 10;
}

.circle .needle.needle1 {
  width: 3px;
  height: 50%;
  background-color: black;
}

.dragged {
  border: 1px solid black;
}

.btn-class {
  border: 5px solid black;
}

.nums1,
.nums2,
.nums3,
.nums4,
.nums5 {
  font-size: 24px;
  letter-spacing: 3px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  border-bottom: 2px solid black;
  padding-bottom: 10px;
}

.nums6 {
  font-size: 24px;
  letter-spacing: 5px;
  writing-mode: vertical-rl;
  text-orientation: upright;
  position: absolute;
  left: 0;
}

.nums7 {
  font-size: 24px;
  letter-spacing: 25px;
  position: absolute;
  bottom: -50px;
  left: 41px;
  margin-left: 4px;
  padding-bottom: 10px;
}

.add-child {
  height: 200px;
  width: 233px;
  margin: auto;
  margin-top: 25px;
  display: inline-flex;
  flex-direction: column-reverse;
  position: relative;
}

.css-18mdkd8 {
  grid-gap: 1.5rem;
}

#add-sub {
  height: 300px;
}

.test_skill {
  border: 2px solid black;
}

.divideboxes {
  height: 36px;
  border: 1px solid black;
  width: fit-content;
  min-width: 36px;
  justify-content: center;
  display: flex;
}

.divide-inner {
  padding-top: 60px;
  width: 100%;
  height: 325px;
  display: flex;
  justify-content: center;
}

.divide-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.divide-main {
  height: 50px;
  width: 50px;
  border: 2px solid green;
  position: relative;
  border-bottom: none;
  border-right: none;
}

.box_1 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.box_2 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: 80px;
}

.divide-left {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translate(-45px, -50%);

}

.divide-line {
  height: 2px;
  width: 65px;
  background-color: black;
  position: absolute;
  top: 100%;
  transform: translate(0%, 45px);
}

.divide-line1 {
  height: 2px;
  width: 60px;
  margin-left: 12px;
  background-color: black;
  position: absolute;
  top: 100%;
  transform: translate(0%, 145px);
}

.divide-line2 {
  height: 2px;
  width: 60px;
  margin-left: 12px;
  background-color: black;
  position: absolute;
  top: 100%;
  transform: translate(0%, 197px);
}

.divide-top {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -45px);
}

.divide-down {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(-50%, 0%);
}

.divide-down1 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(0, 55px);
}

.divide-down2 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(0, 100px);
}

.divide-down3 {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translate(0, 154px);
}


.input-color {
  border: 2px solid var(--chakra-colors-chakra-border-color) !important;
}

.download-image {
  width: 20px;
  margin-left: -10px;
  display: inline;
}

.orientation {
  width: 20px;
  height: 20px;
}

.image-add {
  height: 30px;
}

.css-a5mhaz {
  padding: 0 8px !important;
}

.css-117j187 {
  padding: 10px !important;
}

.accord_btn {
  padding: 8px !important;
}

.img-border p {
  text-align: center;
  font-size: 12px;

}

.css-1rr42hn {
  padding: 0px !important;
}

#tbl {
  display: flex;
}

.box_style {
  /* min-height: 10px; */
  height: 28px;
  width: 28px;
  /* min-width: 10px; */
  background: white;
  border: 1px solid black;
}

.str0,
.str1,
.str2,
.str3,
.str4,
.str5,
.str5,
.str6 {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid black;
  background: brown;
}

.str7 {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid black;
  background: brown;
  transform: translateY(-22px);
}

.new {
  height: 25px;
  width: 25px;
  border-radius: 50%;
  border: 1px solid black;
  background: brown;
  transform: translateY(0px);
}

.btm_abacus {
  display: flex;
  row-gap: 1px;
  flex-direction: column;
  padding: 22px 10px 2px 3px;
  position: relative;
}

.btm_abacus::after {
  height: 100%;
  width: 2px;
  content: "";
  background: black;
  position: absolute;
  left: 40%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: -1;
}
.blink{
    text-align: center;
    /* background: red !important; */
    background: linear-gradient(90deg, #fd0324 0%, #c60000 100%);
    animation: gradient 2.5s infinite 0.8s cubic-bezier(0.2, 0.8, 0.2, 1.2) forwards;
    color: #fff;
    column-gap:10px;
    /* animation: animate 
        1.5s linear infinite; */
}

/* @keyframes animate {
    0% {
        opacity: 0;
    }

    50% {
        opacity: 0.7;
    }

    100% {
        opacity: 0;
    }
} */
@keyframes gradient {
  0% { background: linear-gradient(90deg, #c23e09 0%, #3a47d5 100%) }
  10% { background: linear-gradient(90deg, #d53369 0%, #daae51 100%) }
  20% { background: linear-gradient(90deg, #FDBB2D 0%, #3A1C71 100%) }
  30% { background: linear-gradient(90deg, #FDBB2D 0%, #22C1C3 100%) }
  40% { background: linear-gradient(90deg, #db0b0b 0%, #3ad59f 100%) }
  50% { background: linear-gradient(90deg, #00C9FF 0%, #92FE9D 100%) }
  60% { background: linear-gradient(90deg, #dd250d 0%, #00ff88 100%) }
  70% { background: linear-gradient(90deg, #3F2B96 0%, #A8C0FF 100%) }
  80% { background: linear-gradient(90deg, #FC466B 0%, #3F5EFB 100%) }
  90% { background: linear-gradient(90deg, #e40c05 0%, #182848 100%) }
  100% { background: linear-gradient(90deg, #ec370a 0%, #3a47d5 100%) }
}

input:active, input:focus{
  outline:none !important;

}
.inp_class{
 height:30px;
 width:30px;
 border:2px solid black;
}
#alter{
 display: flex;
 flex-wrap: wrap;
 width:200px;
}
#main {
  display: flex;
  flex-direction: column-reverse;
}
.inp_style{
  width:20px;
  height:20px;
  margin-right:1px;
  /* border-color: #00ff88 !important; */
  /* color: #00C9FF !important; */
  border: 2px solid black;
}
.new_inp{
  min-width:10px;
  min-height:10px;
  max-width:100px;
  max-height:100px;
  margin-right:2px;
  border: 2px solid black;
 
}
/* #main{
  display: none;
} */
#sub{
  margin-top:20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.newtable{
  border-color:red !important;
}
.non-desktop{
  content: "";
display: flex;
justify-content: center;
align-items:center ;
 background-image: url("https://e1.pxfuel.com/desktop-wallpaper/75/692/desktop-wallpaper-14-dark-gray-black-shade.jpg");
background-size: cover;
background-repeat: no-repeat;
background-attachment: fixed;
background-position: center;
opacity: 0.90;
}
.mob-view{
  display: grid;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
 
}
.respo-content{
  font-size: 60px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color:whitesmoke;
  text-align: center;
  border: 10px dotted red;
  padding: 15px;
}

@media (max-width:1198px){
  .dashboard{
    display:none;
  }
}