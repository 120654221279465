.navbar {
  /* padding-top: 20px !important; */
  /* padding-bottom: 20px !important; */
  border: 1px solid #D6D6D6;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
  z-index: 2;
}

.navbar .btn {
  background-color: #7E57C2 !important;
  color: #FFFFFF !important;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 3px 30px;
  border: none;
}

.navbarRight {
  width: 30%;
  justify-content: end;
  margin-right: 10px;
}

.logo {
  width: 200px;
}

.profileBack {
  background: #039EE1;
  margin-bottom: 0;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.profileBack::after {
  position: absolute;
  content: url(./assests/home/down_arrow.svg);
  display: inline-block;
  right: -100%;
  transform: translateX(-50%);
}

#dropdown-basic {
  display: flex;
  align-items: center;
  background: none !important;
  box-shadow: none;
}

.dropdown-toggle::after {
  display: none !important;
}

.sideTab {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.nav-pills .nav-link {
  background-color: white !important;
}

.nav-pills .nav-link:hover {
  background: rgba(126, 87, 194, 0.22) !important;
  ;
}

.tabTitle {
  font-family: 'Inter' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #292D32 !important;
  padding-top: 15px !important;
  padding-bottom: 15px !important;
  display: flex;
  align-items: center;
  background-color: none !important;
  padding-left: 20px !important;
}

.sharedProjects {
  position: relative;
}

.sharedProjects::after {
  position: absolute;
  content: "";
  width: 7px;
  height: 7px;
  background-color: red;
  border-radius: 50%;
  right: 0;
  transform: translateX(-200%);
}

.nav-pills .nav-link.active {
  background: rgba(126, 87, 194, 0.22) !important;
  border-radius: 4px !important;
}

.tab_icon {
  margin-right: 15px;
  width: 20px;
  height: 20px;
}

.sideTab_profile {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}

.initial {
  background: #039EE1;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #FFFFFF;
  text-transform: uppercase;
}

.fullName {
  /* font-family: 'Inter'; */
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  color: #2D2D2D;
  margin-left: 20px;
  text-transform: uppercase;
}

.searchBox_main {
  background: linear-gradient(91.48deg, #8B5FA8 0.1%, #522E8C 100%);
  border-radius: 8px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.searchBox_inner {
  text-align: center;
}

.searchText {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  color: #FFFFFF;
  padding-bottom: 20px;
}

.searchBoxMain {
  position: relative;
  width: 35%;
  margin-left: auto;
  margin-right: auto;
}

.searchBox {
  background: #FFFFFF;
  border-radius: 4px;
  width: 450px;
  height: 50px;
  outline: none;
  padding-left: 54px;
  border: none;
}

.searchIcon {
  position: absolute;
  z-index: 1;
  /* margin-left: 0px; */
  margin-top: 13px;
  width: 24px;
  left: 0;
  transform: translateX(50%);
}

.recentProjects {
  padding-top: 40px;
}

.recentProjects .title {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  color: #1F1C1C;
  padding-bottom: 30px;
  text-align: center;
}

.recentProjects .row {
  row-gap: 40px;
}

.recentProjects .card {
  width: 80%;
  margin: auto;
  position: relative;
  box-shadow: 2px 2px 20px -5px;
}

.recentProjects .card .deleteIcon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(10px, -10px);
  visibility: hidden;
  transition: all .2s ease-in-out;
  width: 25px;
  cursor: pointer;
}

.editIcon {
  height: 33px;
  width: 35px;
}

.recentProjects .card:hover .deleteIcon {
  visibility: visible;
}

.recentProjects .questionPaper {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-top: 15px;
  text-align: center;
}

.questionPaper1 {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #000000;
  margin-bottom: 15px;
  text-align: center;
}

.dropdown-menu {
  min-width: 7rem !important;
}